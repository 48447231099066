<template lang="html">
  <div class="ingredients-simple-list" v-if="initialData.items">
    <div
      class="ingredients-simple-list__item"
      v-for="(ingredient, i) in initialData.items"
      :key="i"
    >
      <div
        class="ingredients-simple-list__item-quantity"
        v-if="ingredient.quantity"
      >
        {{ ingredient.quantity }}
      </div>
      <div class="ingredients-simple-list__item-title" v-if="ingredient.title">
        {{ ingredient.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IngredientsSimpleList',
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  created() {
    console.log(this.initialData);
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.ingredients-simple-list {
  &__item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-quantity {
      @include tcl-big-text;
      color: $orange;
      margin-bottom: 5px;
      word-break: break-word;
      margin-right: 10px;
      width: 50px;
    }

    &-title {
      @include tcl-big-text;
      word-break: break-word;
      flex: 1;
    }

    &-image {
      width: 82px;
      height: 82px;
      margin-bottom: 10px;
      border-radius: 5px;
      overflow: hidden;
      img {
      }
    }
  }
}
</style>
